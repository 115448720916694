import React from "react";

import { Layout, SEO } from '../../../components/structure';
import { Button, Content, Hero, PremUpgradeContainer, Testimonial, PointsLoyalty, CallToAction, SplitCabin, LoyaltyCommerce } from "../../../components/blocks"
import styled, { css } from "styled-components";
import { brand, font, mq } from "../../../styles";
import GatsbyImage from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"



const IndustryCruisePage = (props) => {

  const pageQuery = useStaticQuery(graphql`
    query {
      hero: file(base: { eq: "Plusgrade-Hero-Banner-Cruise.jpg" }) {
       ...FullHeroBackground
      },
      rightArrowIcon: file(base: {eq: "arrow-right-blue.png"}) {
        ...ButtonIcon
      }
      rightArrowIconWhite: file(base: {eq: "arrow-right.png"}) {
        ...ButtonIcon
      }
      cruiseIcon: file(base: {eq: "cruise.png"}) {
        childImageSharp {
          fixed(width: 50, height: 30, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      clickUpgradeIcon: file(base: { eq: "click-upgrade.png" }) {
        childImageSharp {
            fixed(width: 45, height: 80, webpQuality: 100) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
      }
      clickIncreaseIcon: file(base: { eq: "click-increase.png" }) {
          childImageSharp {
              fixed(width: 45, height: 80, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      clickBidIcon: file(base: { eq: "click-bid.png" }) {
          childImageSharp {
              fixed(width: 27, height: 73, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      dollarIncrease: file(base: { eq: "dollar-increase.png" }) {
          childImageSharp {
              fixed(width: 38, height: 60, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      mobileUpgrade: file(base: { eq: "mobile-upgrade.png" }) {
          childImageSharp {
              fixed(width: 50, height: 60, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      pieChart: file(base: { eq: "pie-chart.png" }) {
          childImageSharp {
              fixed(width: 60, height: 60, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      revPlatformBackground: file(base: { eq: "IndustryCruiseRevenuePlatform.jpg" }) {
        childImageSharp {
          fixed(width: 1100, height: 700, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      cruiseTestimonials: file(base: { eq: "IndustryCruiseTestimonials.jpg" }) {
        childImageSharp {
          fixed(width: 1100, height: 600, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      distributeArrow: file(base: { eq: "ArrowDistribute.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, webpQuality: 100){
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      princess: file(base: { eq: "PrincessCruises-Inline.png" }) {
        ...CruiseLogo
      }
      msc: file(base: { eq: "MSCCruises.png" }) {
        ...CruiseLogo
      }
      azamara: file(base: { eq: "AzamaraBlue.png" }) {
        ...CruiseLogo
      }
      virgin: file(base: { eq: "virgin-voyages-inline.png" }) {
        ...CruiseLogo
      }
      uniworld: file(base: { eq: "Uniworld.jpeg" }) {
        ...CruiseLogo
      }
      celeb: file(base: { eq: "CelebrityCruises.png" }) {
        ...CruiseLogo
      }

      norwegian: file(base: { eq: "Norwegian_Cruises_inline.png" }) {
        ...CruiseLogo
      }
      royalcarib: file(base: { eq: "RoyalCaribbean.png" }) {
        ...CruiseLogo
      }

      mobileDevice: file(base: { eq: "Product-Cruise-Premium-Upgrade.png" }) {
        childImageSharp {
            fluid(maxWidth: 260, webpQuality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
    }
    }
  `);

  const PlatformRevenueContainer = styled.div`
  
  ${ font.imports.sherika }
    width: 100%;
    height: auto;
    min-height: 500px;
    margin: auto;
    position: relative;

    .background-con {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      filter: grayscale(1) contrast(1.2);

      &:after {
        content: '';
        position: absolute;
        z-index: 350;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background:linear-gradient(0deg, rgba(0, 4, 20, 0.65), rgba(0, 4, 20, 0.65));
      }

      .gatsby-image-wrapper {
        width: 100% !important;
        height: 100% !important;
      }
    }

    .content-con {
      position: relative;
      z-index: 400;
      width: 95%;
      max-width: 1100px;
      margin: auto;
      height: 100%;
      padding: 75px 0;
      @media only screen and ${mq.maxMd} {
        padding: 30px 0;
      }

      h3 {
        font-size: 38px;
        text-align: center;
        color: ${brand.colors.pg_blue};
        font-weight: 600;
        text-shadow: 0 0 40px ${brand.colors.pg_primary_dark};
      }

      h4 {
        font-size: 24px;
        text-align: center;
        color: #fff;
        font-weight: 600;
      }

      .icon-content-con {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
        top: 130px;
        margin-bottom: 120px;
        @media only screen and ${mq.maxMd} {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          top: 40px;
        }
      }
    }

  `;

  const OutperformContent = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    background-color: ${brand.colors.pg_primary_light};

    @media only screen and ${mq.maxMd} {
      flex-direction: column;
      height: auto;
      padding: 40px 0;
      gap: 40px 0;
    }

    .left-section {
      flex-basis: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .arrow-image-con {
        width: 100%;
        height: 100%;
        @media only screen and ${mq.maxMd} {
          max-width: 500px;
        }
      }
      @media only screen and ${mq.maxMd} {
        width: 100%;
        justify-content: flex-start;
      }
    }

    .right-section {
      height: 450px;
      flex-basis: 50%;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 90px;
      @media only screen and ${mq.maxMd} {
        padding-left: 0;
        width: 80%;
        margin: auto;
        text-align: center;
        align-items: center;
      }

      .blue-highlight {
        font-size: 80px;
        color: ${brand.colors.pg_blue};
        display: block;
      }

      .copy {
        display: block;
        font-size: 24px;
        color: #000414;
        width: 54%;
        max-width: 350px;
        margin: 15px 0;
      }
    }
  `;

  const PlatformPerksContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    max-width: 1050px;
    margin: auto;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    gap: 40px;
    padding: 60px 0;

    @media only screen and ${mq.maxMd} {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .headline-highlight{
      display: none;
    }
  `

const CruiseParnters = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 50px auto;

    .cruise-logo-container {
      width: 95%;
      max-width: 1100px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 40px;

      @media only screen and ${mq.maxMd} {
        flex-wrap: wrap;
        justify-content: center;
      }

      .logo-con {
        flex-basis: 200px;
      }
    }
    
`
  return (
    <Layout>
      <SEO title="Croisière | Plusgrade"
            description={'Assurez-vous que vos catégories supérieures soient toujours complètes et au prix le plus élevé possible.'}
            keywords={'cruise, cruiseline, upgrades'}
      />
      <Content whiteBG>
        <Hero 
          title={'Croisière'}
          image = {pageQuery['hero']}
          icon = {pageQuery['cruiseIcon']}
          subtitle = {"Assurez-vous que vos catégories supérieures soient toujours complètes et au prix le plus élevé possible."}
          buttonURL = {'/fr/contact/demandez-une-demonstration/'}
          buttonLabel = {'Réserver une démonstration'}
          buttonIcon = {pageQuery['rightArrowIconWhite']}
          imageStyle={{objectPosition: "75% bottom"}}
          fullBG
        />


      <CruiseParnters>
        <div className="cruise-logo-container">
          <div className="logo-con">
            <GatsbyImage fluid={pageQuery['norwegian'].childImageSharp.fluid} imgStyle={{
              objectFit: "none",
              objectPosition: "50% 50%",
            }}/>
          </div>
          <div className="logo-con">
            <GatsbyImage fluid={pageQuery['royalcarib'].childImageSharp.fluid} imgStyle={{
              objectFit: "none",
              objectPosition: "50% 50%",
            }}/>
          </div>
          <div className="logo-con">
            <GatsbyImage fluid={pageQuery['princess'].childImageSharp.fluid} imgStyle={{
              objectFit: "none",
              objectPosition: "50% 50%",
            }}/>
          </div>
          <div className="logo-con">
            <GatsbyImage fluid={pageQuery['celeb'].childImageSharp.fluid} imgStyle={{
              objectFit: "none",
              objectPosition: "50% 50%",
            }}/>
          </div>
          <div className="logo-con">
            <GatsbyImage fluid={pageQuery['msc'].childImageSharp.fluid} imgStyle={{
              objectFit: "none",
              objectPosition: "50% 50%",
            }}/>
          </div>
          <div className="logo-con">
            <GatsbyImage fluid={pageQuery['uniworld'].childImageSharp.fluid} imgStyle={{
              objectFit: "none",
              objectPosition: "50% 50%",
            }}/>
          </div>
        </div>
        <Button destination={'/fr/partenaires/'} label={'Voir tous les partenaires'} icon={pageQuery['rightArrowIconWhite']} black whiteText pill/>
      </CruiseParnters>

        <div style={{backgroundColor: "#F6F6F6", paddingTop: "1px"}}>
          <PremUpgradeContainer 
            copy = {"Permettez aux passagers de faire une offre ou d'acheter une cabine surclassée à travers une expérience utilisateur adaptée à votre marque. En tant que solution de surclassement la plus largement adoptée dans le secteur des croisières, Surclassement Premium génère des revenus à marge élevée et offre des expériences client incroyables dans le monde entier."}
            graphicStyle = {{objectPosition: "center bottom", top: "10%"}}
            image={pageQuery['mobileDevice']}
            fr

          />
        </div>
        <PlatformRevenueContainer>
          <div className="background-con">
            <GatsbyImage fixed={pageQuery['revPlatformBackground'].childImageSharp.fixed} imgStyle={{objectFit: "cover", objectPosition: "bottom center"}} />
          </div>
          <div className="content-con">
            <h3>Améliorez vos voyages : fidélisez vos clients et augmentez votre rentabilité</h3>
            <h4>Après avoir un surclassement via la plateforme Plusgrade, lors de leur prochain voyage :</h4>
            <div className="icon-content-con">
              <IconContainer 
                icon = {pageQuery['clickUpgradeIcon']}
                headline = {'18%'}
                copy = {' ont réservé la classe dans laquelle ils avaient été surclassés lors de leur premier voyage'}
              />

              <IconContainer 
                icon = {pageQuery['clickIncreaseIcon']}
                headline = {'4%'}
                copy = {'ont réservé une classe encore plus élevée'}
              />

              <IconContainer 
                icon = {pageQuery['clickBidIcon']}
                headline = {'23%'}
                copy = {'ont fait une offre plus élevée'}
              />
            </div>
          </div>
          
        </PlatformRevenueContainer>
        <OutperformContent>
          <div className="left-section">
            <div className="arrow-image-con">
              <GatsbyImage fluid={pageQuery['distributeArrow'].childImageSharp.fluid} />
            </div>
          </div>
          <div className="right-section">
            <span className="blue-highlight">2x</span>
            <span className="copy">Les programmes de surclassement des croisières alimentés par Plusgrade doublent au minimum au cours de la première année.</span>
          </div>
        </OutperformContent>
        <PlatformPerksContainer>
          <IconContainer whiteOrange
                icon = {pageQuery['dollarIncrease']}
                headline = {'Optimisation des revenus des navires'}
                copy = {'Notre solution analyse l\'inventaire disponible et des surclassements promis pour recommander la sélection optimale de passagers à surclasser.'}
            />

            <IconContainer whiteOrange
                icon = {pageQuery['mobileUpgrade']}
                headline = {'Notre solution encourage les futurs achats'}
                copy = {'Incitez les clients à essayer une cabine supérieure à un prix qu\'ils sont prêts à payer, puis obtenez le double de l\'avantage lorsqu\'ils réservent à nouveau à plein tarif.'}
            />

            <IconContainer whiteOrange
                icon = {pageQuery['pieChart']}
                headline = {'Contrôler l\'inventaire et exploiter les règles de tarification granulaires'}
                copy = {'Votre inventaire ne changera pas, mais la façon dont les clients le voient changera - ce qui augmentera la valeur pour eux et pour vos marques.'}
            />
          
        </PlatformPerksContainer>
        <Testimonial 
          testimonials={
            [
              "Nous avions un processus de surclassement très manuel. La plus grande différence lors de l'introduction de MoveUp [la plateforme de surclassement], est que nous avons réalisé que les clients étaient prêts à payer beaucoup plus que ce que nous aurions jamais demandé. Les offres sont donc plus élevées, les clients sont très enthousiastes et nous avons la possibilité d'atteindre nos clients directement et par l'intermédiaire de notre communauté commerciale. En seulement 3 mois, les revenus ont été multipliés à 10 fois plus que ce que nous aurions fait avec notre programme précédent.--Melissa Beckwith, directrice, gestion des revenus, Celebrity Cruises",
              "Avant Plusgrade, nous avions une équipe de 6 coordinateurs à temps plein avec des incitations à la vente pour envoyer des courriels/appels aux clients pour les surclassements. Aujourd'hui, nous n'avons plus que 3 coordinateurs salariés à temps plein qui effectuent les surclassements dans notre système de réservation. De plus, ils ont la capacité de gérer d'autres responsabilités, comme le contrôle de l'inventaire pour les départs rapprochés. Les trois autres employés ont été réaffectés au sein de l'organisation.--Valeria Nicolaci, directrice du développement des revenus et de l'efficacité des opérations, Norwegian Cruise Lines"
            ]
          }
          signature = {'Paul vR'}
          background={pageQuery['cruiseTestimonials']}
          imageStyle = {{objectPosition: "top center"}}
        />
        <LoyaltyCommerce fr/>
        <PointsLoyalty 
          headline={'Plateforme commerciale de fidélisation'}
          copy={'La Loyalty Commerce Platform de Points, une société de Plusgrade, permet aux membres d\'échanger, de gagner et de convertir leurs points/miles à travers sur des produits auxiliaires de partis tiers ou à travers des collaborations avec d\'autres marques. En connectant votre programme à un réseau de partenaires leaders du secteur, vous pouvez augmenter les revenus auxiliaires et étendre l\'utilité de vos points/miles.'}
          fr
          spaceBetween
        />

        <CallToAction
          title={'Générez des revenus de produits auxiliaires avec des expériences clients incroyables'}
          subtitle={'Contactez-nous aujourd\'hui pour en savoir plus '}
          > 
          <Button destination={'/fr/contact/demandez-une-demonstration/'} label={'Demander une démonstration'} icon={pageQuery['rightArrowIcon']} white blueText pill />
        </CallToAction>

      </Content>
    </Layout>
  );
}

export default IndustryCruisePage;

const IconCon = styled.div`

${ font.imports.sherika }
  flex-basis: 24%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .icon-con {
    @media only screen and ${mq.maxMd} {
      margin: auto;
    }
  }

  .headline {
    font-family: ${font.family.sherika};
    font-weight: 600 !important;
    color: ${brand.colors.pg_blue} !important;
    font-size: 34px !important;
    margin-bottom: 5px;
    @media only screen and ${mq.maxMd} {
      text-align: center;
    }
  }

  .headline-highlight {
    color: ${brand.colors.pg_primary_light};
    margin-top: 30px;
    @media only screen and ${mq.maxMd} {
      text-align: center;
    }
  }

  p{
    margin-top: 10px;
    font-family: ${font.family.sherika};
    font-weight: 700;
    font-size: 16px;
    color: ${brand.colors.pg_primary_light};
    @media only screen and ${mq.maxMd} {
      text-align: center;
    }
  }

  ${props => props.whiteOrange && css`
    flex-basis: 30%;
    align-items: flex-start;
    flex-wrap: wrap;

    .headline-con {
      text-align: left;
      @media only screen and ${mq.maxMd} {
        text-align: center;
        align-items: center;
      }
      h4 {
        color: ${brand.colors.pg_primary_dark} !important;
        font-family: ${font.family.sherika};
        font-size: 24px !important;
        font-weight: 700;
        white-space: pre-line;
      }
      p {
        color: ${brand.colors.pg_primary_dark};
        font-family: ${font.family.sherika};
        font-weight: 400;
        margin: 20px 0;
      }

      .headline{
        min-height: 60px;
        display: flex;
        align-items: center;
        @media only screen and ${mq.maxMd} {
          justify-content: center;
        }
      }
    }
  `}
`;

const IconContainer = ({icon, highlight, headline, copy, ...props }) => {
  const ic = ( icon ? <GatsbyImage fixed={icon.childImageSharp.fixed}/> : <></>);
  return(
    <IconCon {...props}>
      <div className="icon-con">
        {ic}
      </div>
      <div className="headline-con">
      <h4 className="headline-highlight">{highlight}</h4>
        <h4 className="headline">
          {headline}
        </h4>
        <p>
          {copy}
        </p>
      </div>

    </IconCon>
  )
}

export const query = graphql`
  fragment CruiseLogo on File {
    childImageSharp {
      fluid(maxWidth: 125, webpQuality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

